import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Form } from 'antd';
import FormItem from 'shared/ui/form-item';
import Input from 'shared/ui/input';
import UploadAvatar from 'shared/ui/upload-avatar';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { fileToDataUrl } from 'shared/utils';
import { useTranslation } from 'react-i18next';
import Button from 'shared/ui/button';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { Clipboard } from 'features/clipboard';
import { isEqual } from 'lodash';
import { useBoolean } from 'usehooks-ts';
import { putAccountSettings, putUpdateUserProfile } from 'shared/api/requests/app';
import { getMeAction } from 'app/store/actions';
import Select from 'shared/ui/select';
import useIndustries from 'shared/graphql/queries/useIndustries';
import usePositions from 'shared/graphql/queries/usePositions';
import { useCustomNavigator } from 'shared/hooks/useCustomNavigator';
import { PATH_NAME } from 'pages/constants';
import useVideoProvidersList from 'shared/graphql/queries/useVideoProvidersList';
import TextArea from 'shared/ui/textArea/TextArea';

import './styles.scss';

const UpdateUserInfoForm = () => {
  const [file, setFile] = useState<RcFile | undefined>(undefined);
  const [dataUrl, setDataUrl] = useState<string | null>(null);
  const { value: disable, setValue: setDisable } = useBoolean(true);
  const { value: loading, setValue: setLoading } = useBoolean(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const me = useAppSelector((state) => state.app.me);
  const dispatch = useAppDispatch();
  const { industriesForSelect } = useIndustries();
  const { positionsForSelect } = usePositions();
  const { providersForSelect } = useVideoProvidersList();
  const { navigate } = useCustomNavigator();

  const defaultValues = useMemo(() => {
    return {
      name: me?.name || `${me?.first_name} ${me?.last_name}`,
      position: me?.user_details?.position?.id,
      industry: me?.user_details?.industry?.id,
      video_provider: me?.account_data.video_provider_usage.id,
      tone_of_voice: me?.account_data.tone_of_voice,
    };
  }, [me]);

  useEffect(() => {
    if (!file && me) {
      setDataUrl(me?.icon_image);
    }
  }, [file, me]);

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const resetToDefault = useCallback(() => {
    form.setFieldsValue(defaultValues);
    setDisable(true);
    navigate(PATH_NAME.main);
  }, [defaultValues, form, navigate, setDisable]);

  const handleFormChange = useCallback(() => {
    const fields = form.getFieldsValue();
    setDisable(isEqual(defaultValues, fields) || fields?.name === '');
  }, [defaultValues, form, setDisable]);

  const onChangeUpload = (info: UploadChangeParam<UploadFile>) => {
    const f = info.file.originFileObj;
    setFile(f);
    if (f) {
      fileToDataUrl(f).then((value) => {
        const localUrl = value as string;
        setDataUrl(localUrl);
        setDisable(false);
      });
    }
  };

  const onSubmit = useCallback(async () => {
    if (me) {
      setLoading(true);
      const values = form.getFieldsValue();

      const formData = new FormData();
      if (file) {
        formData.append('icon_image', file as Blob);
      }
      formData.append('name', values.name || me?.name);
      if (values.position) {
        formData.append('position', values.position);
      }

      if (values.industry) {
        formData.append('industry', values.industry);
      }

      const payload = {
        video_provider_usage_id: values.video_provider,
        tone_of_voice: values.tone_of_voice,
      };

      await putAccountSettings(payload);

      await putUpdateUserProfile(formData)
        .then(() => {
          dispatch(getMeAction());
          setDisable(true);
          setLoading(false);
        })
        .catch(() => {
          setDisable(true);
          setLoading(false);
        });
    }
  }, [dispatch, file, form, me, setDisable, setLoading]);

  return (
    <Form
      name="user-form"
      form={form}
      requiredMark={false}
      className="user-form"
      layout="vertical"
      onFinish={onSubmit}
      onFieldsChange={handleFormChange}
    >
      <UploadAvatar
        className="mb-4"
        texts={{
          modalTitle: t<string>('avatarUploadCrop.title'),
          modalOk: t<string>('avatarUploadCrop.ok'),
          modalCancel: t<string>('avatarUploadCrop.cancel'),
        }}
        file={dataUrl}
        onChangeUpload={onChangeUpload}
      />
      <FormItem name="name" label={t('loginForm.name.label')}>
        <Input size="large" exSize="xl" placeholder={t<string>('loginForm.name.placeholder')} disabled={loading} />
      </FormItem>
      <FormItem name="email" label={t('loginForm.email.label')}>
        <div className="d-flex flex-row align-center">
          <span className="user-form__emeil-text">{me?.email}</span>
          <Clipboard.CopyButton text={me?.email} />
        </div>
      </FormItem>
      <FormItem
        name="position"
        label={t<string>('loginForm.position.label')}
        rules={[{ required: true, type: 'string', message: t<string>('loginForm.error.required') }]}
      >
        <Select
          options={positionsForSelect}
          size="large"
          exSize="xl"
          placeholder={t<string>('loginForm.position.placeholder')}
        />
      </FormItem>
      <FormItem
        name="industry"
        label={t<string>('loginForm.industry.label')}
        rules={[{ required: true, type: 'string', message: t<string>('loginForm.error.required') }]}
      >
        <Select
          options={industriesForSelect}
          size="large"
          exSize="xl"
          placeholder={t<string>('loginForm.industry.placeholder')}
        />
      </FormItem>

      <FormItem label={t<string>('settingsForm.toneOfVoice.label')} name="tone_of_voice">
        <TextArea
          placeholder={t<string>('settingsForm.toneOfVoice.placeholder')}
          size="large"
          autoSize={{ minRows: 2, maxRows: 10 }}
        />
      </FormItem>

      <FormItem
        name="video_provider"
        label={t<string>('loginForm.videoProvider.label')}
        rules={[{ required: true, type: 'string', message: t<string>('loginForm.error.required') }]}
      >
        <Select
          options={providersForSelect}
          size="large"
          exSize="xl"
          placeholder={t<string>('loginForm.videoProvider.placeholder')}
        />
      </FormItem>
      <div className="user-form-apikey">
        <span className="user-form-apikey__title">API KEY:</span>
        <span className="user-form-apikey__text">{me?.account_data.api_key}</span>
      </div>
      <div className="mt-6">
        <Button
          className="mr-4"
          size="large"
          exSize="xl"
          htmlType="submit"
          disabled={disable}
          type="primary"
          loading={loading}
        >
          {t<string>('common.save')}
        </Button>
        <Button size="large" exSize="xl" onClick={resetToDefault} disabled={loading}>
          {t<string>('common.cancel')}
        </Button>
      </div>
      <Button className="mt-6" type="link" onClick={() => navigate(PATH_NAME.changePass)}>
        {t<string>('loginForm.change')}
      </Button>
    </Form>
  );
};

export default UpdateUserInfoForm;
