import { useCallback, useEffect, useMemo, useState } from 'react';

import { Form, message, UploadProps } from 'antd';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  postOutlineBuilderProjectFileRequest,
  postOutlineBuilderProjectRequest,
  putOutlineBuilderProjectRequest,
} from 'shared/api/requests/outline-builder-project';
import { PATH_NAME } from 'pages/constants';
import { getProjectAction } from 'modules/project/actions';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'usehooks-ts';
import { RcFile } from 'antd/es/upload';
import { isEmpty } from 'lodash';
import {
  deleteFileByIdRequest,
  deleteOutlineBuilderContextFile,
  postCreateFileRequest,
} from 'shared/api/requests/file';
import { onUploadFileInPartRequest } from 'shared/api/fetches/fileUpload';
import { ProjectContextFile } from 'shared/types/entities';
import { checkMimeType } from 'shared/utils';
import useCourseTypes from 'shared/graphql/queries/useCourseTypes';
import getObjectKey from 'shared/utils/getObjectKey';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import {
  finishUpload,
  removeProjectFileTemp,
  setFileSize,
  setProgress,
  setProjectFilesTemp,
  startUpload,
} from '../project';
import { createProjectVersionAction } from '../about-page-form/store/actions';
import { runTutor } from '../tutorial/store';

import { levelMarks } from './constants';

const useSettingForm = () => {
  const { id, lang } = useParams();
  const [files, setFiles] = useState<ProjectContextFile[]>([]);
  const [autoSteps, setAutoSteps] = useState<boolean>(false);
  const { value: isLong, setValue: onToggleLong } = useBoolean(false);
  const store = useAppSelector((state) => state.project);
  const projectFilesTemp = useAppSelector((state) => state.project.projectFilesTemp);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isTourActive = useAppSelector((state) => state.tutorial.tourActive);
  const { i18n } = useTranslation();
  const [form] = Form.useForm();
  const { courseTypes } = useCourseTypes();
  //const hasSubscription = useAppSelector((state) => state.app?.me?.has_active_subscription);

  const aboutAnnotation = Form.useWatch('about_annotation', form);
  const goalAnnotation = Form.useWatch('goal_annotation', form);
  const projectTypeFK = Form.useWatch('project_type_fk', form);

  const isShowWebinarDuration = courseTypes?.find((ct) => ct.id === projectTypeFK)?.code === 'live';
  const isShowStudyLoad = courseTypes?.find((ct) => ct.id === projectTypeFK)?.code === 'pre_recorded';

  // Default values for form
  const defaultValues = useMemo(() => {
    return {
      about_annotation: store.project?.about_annotation || '',
      goal_annotation: store.project?.goal_annotation || '',
      prerequisites: store.project?.prerequisites || '',
      knowledge_level: store.project?.knowledge_level ? getObjectKey(levelMarks, store.project?.knowledge_level) : 2,
      duration: store.project?.duration ? Number(store.project?.duration) : 4,
      practical_level: store.project?.practical_level ? getObjectKey(levelMarks, store.project?.practical_level) : 2,
      language: store.project?.active_project_version?.language?.code || i18n.language,
      project_type_fk: store.project?.project_type_fk?.id,
      webinar_duration: store.project?.webinar_duration,
      course_study_load: store.project?.course_study_load ? Number(store.project?.course_study_load) : 4,
    };
  }, [i18n.language, store.project]);

  //Run Tour
  useEffect(() => {
    if (!store.loading && store?.project?.id && isTourActive) {
      dispatch(runTutor());
    }
  }, [dispatch, isTourActive, store.loading, store?.project?.id]);

  useEffect(() => {
    form.setFieldValue('duration', isLong ? 5 : 2);
  }, [form, isLong]);

  //Init form values
  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [defaultValues, form]);

  const onToggleAutoSteps = useCallback((checked: boolean) => {
    setAutoSteps(checked);
  }, []);

  const onAttachFilesToProject = useCallback(
    async (projectId: string) => {
      for await (const fileId of projectFilesTemp) {
        if (projectId) {
          const payloadFile = {
            file_id: fileId,
          };
          await postOutlineBuilderProjectFileRequest(projectId, payloadFile);
        }
      }
      dispatch(setProjectFilesTemp(null));
    },
    [dispatch, projectFilesTemp]
  );

  const onSubmit = useCallback(async () => {
    const values = form.getFieldsValue();

    const payload = {
      about_annotation: values.about_annotation,
      goal_annotation: values.goal_annotation,
      prerequisites: values.prerequisites,
      knowledge_level: levelMarks[values.knowledge_level],
      practical_level: levelMarks[values.practical_level],
      duration: values.duration,
      auto_steps: autoSteps,
      language: values.language,
      project_type_fk_id: values.project_type_fk,
      webinar_duration: values.webinar_duration,
      course_study_load: values.course_study_load || 4,
    };

    if (!isEmpty(files)) {
    }

    if (id === 'new') {
      mixpanel.track('Project Generate Description', { ProjectId: 'new' });
      await postOutlineBuilderProjectRequest(payload).then(async (res) => {
        await onAttachFilesToProject(res.id);
        await dispatch(createProjectVersionAction(res.id, values.language)).then(() => {
          if (!autoSteps) {
            const path = generatePath(PATH_NAME.courseManualWithEntity, {
              id: res.id,
              lang: values.language,
              entity: PATH_NAME.courseEntity.about,
            });
            navigate(path);
          } else {
            navigate(PATH_NAME.main);
          }
        });
      });
    } else {
      if (id) {
        mixpanel.track('Project Generate Description', { ProjectId: id });
        await putOutlineBuilderProjectRequest(id, payload).then((res) => {
          dispatch(createProjectVersionAction(res.id, values.language)).then(() => {
            if (!autoSteps) {
              const path = generatePath(PATH_NAME.courseManualWithEntity, {
                id: id,
                lang: values.language,
                entity: PATH_NAME.courseEntity.about,
              });
              navigate(path);
            } else {
              navigate(PATH_NAME.main);
            }
          });
        });
      }
    }
  }, [autoSteps, dispatch, files, form, id, navigate, onAttachFilesToProject]);

  const onUploadProgress = useCallback(
    (progress: number) => {
      dispatch(setProgress(progress));
    },
    [dispatch]
  );

  const onSetFileSize = useCallback(
    (fileSize: number) => {
      dispatch(setFileSize(fileSize));
    },
    [dispatch]
  );

  const onStartUpload = useCallback(() => dispatch(startUpload()), []);
  const onFinishUpload = useCallback(() => dispatch(finishUpload()), []);

  const onBeforeUpload = useCallback(
    async (file: RcFile) => {
      const { isPdf } = checkMimeType(file);
      if (!isPdf) {
        void message.error('Allowed file format: PDF', 5);
      } else {
        onStartUpload();
        onSetFileSize(file.size);
        const payload = {
          file_name: file.name || '',
        };
        const response = await postCreateFileRequest('cob_file', payload);

        if (response.id && id) {
          const onAfterUpload = () => {
            if (id === 'new') {
              const temp = {
                id: file.uid,
                context_file: {
                  id: file.uid,
                  state: null,
                  file: {
                    id: response.id.toString(),
                    name: file.name,
                    file_type: 'cob_file',
                  },
                },
              };
              setFiles((prev) => [...prev, temp]);
              dispatch(setProjectFilesTemp(response.id));
            } else {
              const payloadFile = {
                file_id: response.id,
              };
              postOutlineBuilderProjectFileRequest(id, payloadFile).then(() => {
                if (id !== 'new') {
                  void dispatch(getProjectAction(id, true, undefined, lang));
                }
              });
            }
          };

          await onUploadFileInPartRequest({
            file,
            fileId: response.id,
            callbackComplete: onAfterUpload,
            updateUpload: onUploadProgress,
            callback: onFinishUpload,
          });
        }
      }
      return false;
    },
    [dispatch, id, lang, onFinishUpload, onSetFileSize, onStartUpload, onUploadProgress]
  );

  const customRequest = () => null;

  const onRemoveFile = useCallback(
    async (fileId: string) => {
      if (id === 'new') {
        await deleteFileByIdRequest('cob_file', fileId).then(() => {
          dispatch(removeProjectFileTemp(Number(fileId)));
          setFiles((prev) => {
            const filtered = prev.filter((f) => f.context_file.file.id !== fileId) || [];
            return [...filtered];
          });
        });
      } else {
        if (id) {
          await deleteOutlineBuilderContextFile(fileId);
          void dispatch(getProjectAction(id, true, undefined, lang));
        }
      }
    },
    [dispatch, id, lang]
  );

  const uploadProps: UploadProps = {
    customRequest,
    showUploadList: false,
    beforeUpload: onBeforeUpload,
    multiple: false,
  };

  return {
    isLong,
    files,
    form,
    autoSteps,
    disableSubmit: !(aboutAnnotation && goalAnnotation && projectTypeFK),
    isShowWebinarDuration,
    isShowStudyLoad,
    projectFiles: id === 'new' ? files : store.project?.context_files,
    loading: store.loading,
    uploadProps,
    onRemoveFile,
    onToggleLong,
    onToggleAutoSteps,
    onSubmit,
  };
};

export default useSettingForm;
