import { priceModel } from 'entities/price';

export interface User {
  id: string;
  email: string;
  name: string;
  icon_image: string;
  state: string;
}

export type PromoMessageData = {
  id: string;
  type: 'alert' | 'card';
  priority: number;
  title: string;
  description: string;
  button_name: string;
  button_href: string;
};

export type PromoMessage = {
  id: string;
  is_active: boolean;
  promo_message: PromoMessageData;
};

export type UserDetails = {
  industry: {
    id: string;
  };
  position: {
    id: string;
  };
};

export type VideoProviderType = {
  id: string;
  pk: string;
  is_default: boolean;
  is_public: boolean;
  name: string;
  system_key: string;
};

export type AccountData = {
  tone_of_voice: string;
  video_provider: string;
  is_video_enabled: boolean;
  subscription_cancel_dt: string;
  subscription_expire_at: string;
  api_key: string;
  tokens_left: number;
  video_seconds_left: number;
  affiliate_tokens_left: number;
  subscription: priceModel.Subscription;
  dcconnector_accounts: DCConnectorAccount[];
  video_provider_usage: {
    id: string;
    pk: string;
    is_active: boolean;
    licence_type: string;
    video_provider: VideoProviderType;
  };
};

export interface UserRoleBase {
  id: string;
  name: string;
  role: BusinessRoleEnum;
  pk?: string;
}

export interface Company {
  id: string;
  slug?: string;
  name: string | null;
  company_type: CompanyTypeEnum;
}

export interface DCConnectorAccount {
  id: string;
  pk: string;
  external_account_id: string;
  is_active: boolean;
  params: string;
  state: string;
  dc_connector: {
    id: string;
    connector_name: string;
    connector_type: string;
  };
}

export type Me = {
  id: string;
  affiliate_username: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  icon_image: string;
  timezone: string;
  cob_tokens_left: number;
  signup_promo_code: string;
  has_active_subscription: boolean;
  password_is_set: boolean;
  state: 'BLOCKED' | 'INVITED' | 'REGISTERED';
  companies: {
    id: string;
    company: Company;
    business_roles: UserRoleBase[];
  }[];
  promo_messages: PromoMessage[];
  user_details: UserDetails | null;
  account_data: AccountData;
};

export enum BusinessRoleEnum {
  author = 'AUTHOR',
  authorManager = 'AUTHOR_MANAGER', //Руководитель авторского коллектива
  executiveManager = 'EXECUTIVE_MANAGER', //Руководитель производства
  manager = 'MANAGER', //Продюсер
  instructionalDesigner = 'INSTRUCTIONAL_DESIGNER', //Methodist
  learningTechnologist = 'LEARNING_TECHNOLOGIST',
  reviewer = 'REVIEWER',
  watcher = 'WATCHER',
  reader = 'READER',
  videoProductionExecutor = 'VIDEO_PRODUCTION_EXECUTOR',
  videoProductionManager = 'VIDEO_PRODUCTION_MANAGER', //Руководитель видеопроизводства
  productionExecutor = 'PRODUCTION_EXECUTOR', // Исполнитель
  stakeholder = 'STAKEHOLDER',
  admin = 'ADMIN',
  support = 'SUPPORT',
  support_manager = 'SUPPORT_MANAGER',
}

export enum CompanyTypeEnum {
  opm = 'OPM',
  video_production = 'VIDEO_PRODUCTION',
}
