import React, { memo, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Alert, Form } from 'antd';
import TextArea from 'shared/ui/textArea';
import FormItem from 'shared/ui/form-item';
import Slider from 'shared/ui/slider';
import Spinner from 'shared/ui/spinner/Spinner';
import Select from 'shared/ui/select';
import useCourseTypes from 'shared/graphql/queries/useCourseTypes';
import FormFooter from 'shared/components/form-footer';
import SettingSwitchBlock from 'shared/components/setting-switch-block';
import FilesUpload from 'shared/components/files-upload';
import FilesUploadList from 'shared/components/files-upload-list';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { sortLanguages } from 'shared/utils';
import { useOwner } from 'shared/hooks/useProjectOwner';

import { resetFileSize, resetProgress, setProjectFilesTemp } from '../project';

import {
  levelMarksView,
  practiceMarksView,
  studyLoad,
  webinarDuration,
  weeksMarks,
  weeksMarksShort,
} from './constants';
import useSettingForm from './useSettingForm';

import './styles.scss';
import { selectedHasSubscription } from '../../app/store/app.selectors';

const SettingsPageForm: React.FC = () => {
  const { t } = useTranslation();
  const {
    isLong,
    isShowWebinarDuration,
    isShowStudyLoad,
    projectFiles,
    form,
    files,
    autoSteps,
    disableSubmit,
    loading,
    uploadProps,
    onRemoveFile,
    onToggleLong,
    onToggleAutoSteps,
    onSubmit,
  } = useSettingForm();
  const { courseTypesForSelect } = useCourseTypes();
  const dispatch = useAppDispatch();

  const hasSubscription = useAppSelector(selectedHasSubscription);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const tokens = useAppSelector((state) => state.app?.me?.cob_tokens_left) || 0;
  const languages = useAppSelector((state) => state.app.languages);
  const totalSize = useAppSelector((state) => state.project.totalFilesSize);
  const progress = useAppSelector((state) => state.project.progress);
  const activeUploads = useAppSelector((state) => state.project.activeUploads);
  const langOptions = sortLanguages(languages).map((el) => {
    return {
      value: el.code,
      label: el.name,
    };
  });

  useEffect(() => {
    if (Boolean(totalSize) && !Boolean(activeUploads)) {
      dispatch(resetFileSize());
      dispatch(resetProgress());
    }
    return () => {
      dispatch(resetFileSize());
      dispatch(resetProgress());
    };
  }, [activeUploads, dispatch, totalSize]);

  useEffect(() => {
    return () => {
      dispatch(setProjectFilesTemp(null));
    };
  }, []);

  const { isOwner } = useOwner();

  const disabled = !isOwner || !!selectedVersion || Boolean(activeUploads);

  return loading ? (
    <Spinner />
  ) : (
    <Form name="settings-form" className="settings-form" layout="vertical" form={form}>
      <FormItem name="about_annotation" className="mb-12" label={t<string>('settingsForm.about.label')}>
        <TextArea
          placeholder={t<string>('settingsForm.about.placeholder')}
          size="large"
          autoSize={{ minRows: 2, maxRows: 10 }}
          disabled={disabled}
        />
      </FormItem>
      <FormItem name="goal_annotation" className="mb-12" label={t<string>('settingsForm.goal.label')}>
        <TextArea
          placeholder={t<string>('settingsForm.goal.placeholder')}
          size="large"
          autoSize={{ minRows: 2, maxRows: 10 }}
          disabled={disabled}
        />
      </FormItem>
      <FormItem name="prerequisites" className="mb-12" label={t<string>('settingsForm.prerequisites.label')}>
        <TextArea
          placeholder={t<string>('settingsForm.prerequisites.placeholder')}
          size="large"
          autoSize={{ minRows: 2, maxRows: 10 }}
          disabled={disabled}
        />
      </FormItem>

      <span className="settings-form__label">{t('settingsForm.materials')}</span>
      <FilesUpload className="mb-2" {...uploadProps} disabled={disabled || !!projectFiles?.length} />

      {!hasSubscription ? <Alert className="mb-4" message={t('messages.only5Pages')} type="warning" /> : null}
      <FilesUploadList
        className="mb-8"
        isLoading={Boolean(activeUploads)}
        percent={(progress / totalSize) * 100}
        files={projectFiles}
        onRemoveFile={onRemoveFile}
        disabled={disabled}
      />

      <div className="settings-form__wrap">
        <span className="settings-form__label">{t<string>('settingsForm.contentLang.label')}</span>
        <div className="settings-form__row mb-5">
          <FormItem name="language" className="settings-form__lang">
            <Select
              disabled={disabled}
              placeholder={t<string>('settingsForm.contentLang.placeholder')}
              options={langOptions}
            />
          </FormItem>
        </div>
        <span className="settings-form__label">{t<string>('settingsForm.adjustSettings')}</span>
        <div className="settings-form__row-course-type mb-5">
          <FormItem
            name="project_type_fk"
            label={t<string>('settingsForm.courseType.label')}
            className="settings-form__course-types"
          >
            <Select
              disabled={disabled}
              placeholder={t<string>('settingsForm.courseType.placeholder')}
              options={courseTypesForSelect}
            />
          </FormItem>

          {isShowWebinarDuration ? (
            <FormItem
              name="webinar_duration"
              className="settings-form__slider-webinar"
              label={t<string>('settingsForm.webinar.label')}
            >
              <Slider
                disabled={disabled}
                marks={webinarDuration}
                min={30}
                max={120}
                step={15}
                tooltip={{ formatter: null }}
              />
            </FormItem>
          ) : null}

          {isShowStudyLoad ? (
            <FormItem
              name="course_study_load"
              className="settings-form__slider-webinar"
              label={t<string>('settingsForm.studyLoad.label')}
            >
              <Slider marks={studyLoad} min={2} max={10} step={1} tooltip={{ formatter: null }} />
            </FormItem>
          ) : null}
        </div>
        <div className="settings-form__row mb-5">
          <SettingSwitchBlock
            label={t<string>('settingsForm.moocText.label')}
            text={t<string>('settingsForm.moocText.text')}
            switchProps={{ disabled: true, checked: true }}
            disabled={disabled}
          />
          <SettingSwitchBlock
            label={t<string>('settingsForm.projectBasedText.label')}
            text={t<string>('settingsForm.projectBasedText.text')}
            switchProps={{ disabled: true, checked: false }}
            disabled={disabled}
          />
          <SettingSwitchBlock
            label={t<string>('settingsForm.assignmentsText.label')}
            text={t<string>('settingsForm.assignmentsText.text')}
            switchProps={{ disabled: true, checked: false }}
            disabled={disabled}
          />
          <SettingSwitchBlock
            isBeta
            label={t<string>('settingsForm.long.label')}
            text={t<string>('settingsForm.long.text')}
            switchProps={{ disabled: false, checked: isLong, onChange: onToggleLong }}
            disabled={disabled}
          />
        </div>
        <div className="settings-form__row mb-5 settings-form__row-sliders">
          <FormItem
            name="knowledge_level"
            className="settings-form__slider tour--slider"
            label={t<string>('settingsForm.knowledgeLevel')}
          >
            <Slider disabled={disabled} marks={levelMarksView(t)} min={1} max={3} tooltip={{ formatter: null }} />
          </FormItem>
          <FormItem name="duration" className="settings-form__slider" label={t<string>('settingsForm.durationWeeks')}>
            <Slider
              disabled={disabled}
              marks={isLong ? weeksMarks : weeksMarksShort}
              min={isLong ? 5 : 1}
              max={isLong ? 8 : 4}
              tooltip={{ formatter: null }}
            />
          </FormItem>
          <FormItem
            name="practical_level"
            className="settings-form__slider"
            label={t<string>('settingsForm.practical')}
          >
            <Slider disabled={disabled} marks={practiceMarksView(t)} min={1} max={3} tooltip={{ formatter: null }} />
          </FormItem>
        </div>
        <div className="settings-form__row mb-5">
          <SettingSwitchBlock
            disabled={disabled || !hasSubscription}
            type="long"
            label={t<string>('settingsForm.autoGen.label')}
            text={t<string>('settingsForm.autoGen.text')}
            switchProps={{ onChange: onToggleAutoSteps }}
          />
        </div>
      </div>

      <FormFooter
        classNameSubmit="tour--settings-gen"
        hasPopup={autoSteps && tokens < 5}
        popupTitle={t<string>('settingsForm.notTokens.text1')}
        popupText={t<string>('settingsForm.notTokens.text2')}
        labelSubmit={t<string>(autoSteps ? 'settingsForm.autoGenerateCourse' : 'settingsForm.generateCourse')}
        disabledSubmit={disableSubmit || disabled}
        onSubmit={onSubmit}
      />
    </Form>
  );
};

export default memo(SettingsPageForm);
